<template>
  <div class="body">
    <div class="applications-header">
      <div class="applications-title">{{ $t("nav.applications") }}</div>
      <div class="choose_option">
        <a
          class="layout__link__filters"
          :class="{ layout__link_visit: selectedSection === 'add' }"
          @click="showSection('add')"
        >
          <img src="@/assets/add.svg" alt="Добавить" class="icon" />
          Добавить
        </a>
        <a
          class="layout__link__filters"
          :class="{ layout__link_visit: selectedSection === 'filters' }"
          @click="showSection('filters')"
        >
          <img src="@/assets/filters.svg" alt="фильтр" class="icon" />
          Фильтры
        </a>
      </div>
    </div>

    <div
      class="text-fields-block block-wide"
      v-if="
        selectedSection === 'add' &&
        ($auth.check({ permissions: 'application_permission' }) ||
          $auth.check({ name: 'admin' }))
      "
    >
      <form @submit.prevent="add_application" @change="validate">
        <applications-text-fields
          ref="add_application"
          :for_table="false"
        ></applications-text-fields>
        <applications-check-block
          ref="add_application_checkblock"
        ></applications-check-block>

        <div
          class="text-fields-block__btn-row text-fields-block__btn-row_accounts"
        >
          <loader v-if="info.loader"></loader>
          <custom-btn
            v-else
            class="button button_theme_blue"
            type="submit"
            :disabled="!new_application.city_id"
          >
            {{ $t("applications.create") }}
          </custom-btn>
          <span
            v-if="info.type"
            class="text-fields-block__info-text text_color_green"
            :class="{ text_color_red: info.error }"
          >
            {{ info_text }}
          </span>
        </div>
      </form>
    </div>

    <div class="applications__filter_row" v-if="selectedSection === 'filters'">
      <div class="applications__filter-block">
        <select-filter
          class="monitoring__filter-block-item"
          :title="$t('team.city')"
          name="city"
          :items="city_filters"
          @change="set_filter"
        ></select-filter>
        <select-filter
          class="monitoring__filter-block-item"
          :title="$t('team.pos')"
          name="pos_name"
          :items="pos_filters"
          :disabled="pos_disabled"
          @change="set_filter"
        ></select-filter>
        <span
          class="applications__filter-block-clear-btn"
          :class="{
            'applications__filter-block-clear-btn_disabled': disabled_clear_btn,
          }"
          @click="clear_filters"
        >
          {{ $t("team.clear_filters") }}
        </span>
        <appearance-filter
          :title="$t('monitoring.appearance')"
          name="appearance_application"
          class="select-filter_appearance-applications"
          :items="headers"
          @change="set_appearance_filter"
        ></appearance-filter>
      </div>
    </div>

    <div class="applications__table block-wide">
      <applications-table
        v-if="applications.length > 0"
        :header_items="filters_header"
        :items="applications"
        @change_sorted="change_sorted"
      ></applications-table>
      <div v-else class="applications-no-content__text">
        {{ $t("applications.no_content") }}
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationsTextFields from "@/components/components/applicationsTextFields";
import CustomBtn from "@/components/recurringComponents/customBtn";
import ApplicationsTable from "@/components/components/applicationsTable";
import ApplicationsCheckBlock from "@/components/components/applicationsCheckBlock";
import AppearanceFilter from "@/components/recurringComponents/appearanceFilter";
import Loader from "@/components/recurringComponents/loader";
import SelectFilter from "@/components/recurringComponents/selectFilter.vue";
export default {
  name: "applications",
  components: {
    SelectFilter,
    ApplicationsTextFields,
    ApplicationsCheckBlock,
    ApplicationsTable,
    AppearanceFilter,
    CustomBtn,
    Loader,
  },
  data() {
    return {
      selectedSection: null,
      info: {
        error: false,
        type: "",
        text: "",
        loader: false,
      },
      headers: [
        {
          name: "app_id",
          title: this.$i18n.t("applications.headers.app_id"),
          sorted: 1,
        },
        {
          name: "employee",
          title: this.$i18n.t("applications.headers.employee"),
          sorted: 0,
        },
        {
          name: "pos_name",
          title: this.$i18n.t("applications.headers.pos_name"),
          sorted: 0,
        },
        {
          name: "city",
          title: this.$i18n.t("applications.headers.city"),
          sorted: 0,
        },
        {
          name: "comment",
          title: this.$i18n.t("applications.headers.comment"),
          sorted: 0,
        },
        {
          name: "recording_mode_text",
          title: this.$i18n.t("applications.headers.recording_mode_text"),
          sorted: 0,
        },
        {
          name: "status",
          title: this.$i18n.t("applications.headers.status"),
          sorted: 0,
        },
        {
          name: "badge_id",
          title: this.$i18n.t("applications.headers.badge_id"),
          sorted: 0,
        },
        {
          name: "fw_version",
          title: this.$i18n.t("applications.headers.fw_version"),
          sorted: 0,
        },
      ],
    };
  },
  created() {
    if (this.selected_filters.city.length != 0) {
      this.selected_filters.city = [];
    };

    if (this.selected_filters.pos_name.length != 0) {
      this.selected_filters.pos_name = [];
    };

    this.$store.dispatch("load_applications");
    this.$store.dispatch("reset_application");
  },
  watch: {
    "$i18n.locale"() {
      for (let item of this.headers) {
        item.title = this.$t(`applications.headers.${item.name}`);
      }
    },
    selected_filters: {
      handler() {
        this.$forceUpdate();
      },
      deep: true,
    },
    pos_disabled() {
      if (this.selected_filters.city.length === 0) {
        this.selected_filters.pos_name = [];
      }
    },
    pos_filters(newValue) {
      this.selected_filters.pos_name = this.selected_filters.pos_name.filter(
        (x) => newValue.some((item) => item.value === x)
      );
    },
  },
  computed: {
    new_application() {
      return this.$store.getters.NEW_APPLICATION;
    },
    filters() {
      return {
        city: this.$store.getters.FILTERS.city || [],
        pos_name: this.$store.getters.FILTERS.pos_name || [],
      };
    },
    selected_filters() {
      return this.$store.getters.SELECTED_FILTERS;
    },
    disabled_clear_btn() {
      return (
        !this.selected_filters.city.length &&
        !this.selected_filters.pos_name.length
      );
    },
    applications() {
      const applicationsData = this.$store.getters.APPLICATIONS;
      const filteredApplications = applicationsData.filter((application) => {
        const cityMatch =
          this.selected_filters.city.length === 0 ||
          this.selected_filters.city.includes(application.pos.city.name);
        const posMatch =
          this.selected_filters.pos_name.length === 0 ||
          this.selected_filters.pos_name.includes(application.pos.name);

        return cityMatch && posMatch;
      });

      return filteredApplications.map((application) => {
        return {
          id: application.id,
          app_id: application.app_id,
          employee: application.employee,
          employee_id: application.employee_id,
          password: application.password,
          email: application.email,
          pos_name: application.pos.name,
          city: application.pos.city.name,
          pos_address: application.pos.address,
          comment: application.comment,
          pos_id: application.pos.id,
          city_id: application.pos.city.id,
          recording_mode_text: application.recording_mode.includes("button")
            ? this.$t("applications.on_button_press")
            : this.$t("applications.on_timer"),
          recording_mode: application.recording_mode.includes("button"),
          motion_detection: application.motion_detection,
          recording_start_time: application.recording_start_time,
          recording_end_time: application.recording_end_time,
          status: application.status,
          badge_id: application.badge_id,
          fw_version: application.fw_version,
        };
      });
    },
    info_text() {
      if (this.info.text) {
        return this.info.text;
      }
      switch (this.info.type) {
        case "required":
          return this.$i18n.t("errors.required");
        case "invalid_range":
          return this.$i18n.t("errors.invalid_range");
        case "no_mail":
          return this.$i18n.t("applications.errors.no_mail");
        case "mail_exist":
          return this.$i18n.t("applications.errors.mail_exist");
        case "no_format":
          return this.$i18n.t("errors.format");
        case "no_password":
          return this.$i18n.t("errors.no_password");
        case "progress":
          return this.$i18n.t("applications.loader.progress");
        case "failed":
          return this.$i18n.t("applications.loader.failed");
        case "success":
          return this.$i18n.t("applications.loader.success");
        default:
          return "";
      }
    },
    filters_header() {
      let temp = [];
      for (let header of this.headers) {
        if (
          this.$store.getters.FILTERS["appearance_application"].includes(
            header.name
          )
        ) {
          temp.push(header);
        }
      }
      return this.$store.getters.FILTERS["appearance_application"].length === 0
        ? this.headers
        : temp;
    },
    city_filters() {
      const filters = Array.from(
        new Set(
          this.$store.getters.APPLICATIONS.map((app) => app.pos.city.name)
        )
      ).map((item) => {
        return { title: item, value: item, selected: false };
      });
      console.log("Города:", filters);
      return filters;
    },
    pos_filters() {
      var filters = [];
      var poses = this.$store.getters.APPLICATIONS.map((app) => app.pos);
      var all_poses = [];
      var counter = {};

      for (let i = 0; i < poses.length; ++i) {
        var pos_id = poses[i].id

        if (counter[pos_id] === undefined) {
          counter[pos_id] = 0
        }

        if (counter[pos_id] > 0) {
          continue
        } else {
          all_poses.push(poses[i])
          counter[pos_id] = 1
        }
      } 

      var cityFilters = this.selected_filters.city;
      
      for (let i = 0; i < all_poses.length; ++i) {
        if (cityFilters.includes(all_poses[i].city.name)) {
          filters.push({title: all_poses[i].name, value: all_poses[i].name, selected: false})
        }
      };

      // console.log("POS:", filters);
      return filters;
    },
    pos_disabled() {
      return this.selected_filters.city.length === 0;
    },
  },
  methods: {
    showSection(section) {
      this.selectedSection = this.selectedSection === section ? null : section;
    },

    change_sorted(item) {
      let change_item = this.headers.find((it) => it.name === item);
      let sort = change_item.sorted;

      this.headers.forEach((item) => (item.sorted = 0));

      if (sort !== 1) change_item.sorted = 1;
      else change_item.sorted = -1;

      if (change_item.sorted !== 0) {
        this.applications.sort((a, b) => {
          if (a[item] > b[item]) return change_item.sorted;
          if (a[item] === b[item]) return 0;
          if (a[item] < b[item]) return -change_item.sorted;
        });
      }
    },
    set_appearance_filter() {
      this.$store.dispatch("apply_appearance_application_filters");
    },
    validate() {
      let valid = this.$refs.add_application.validate();
      let valid_checkblock = this.$refs.add_application_checkblock.validate();
      this.info.error = !(valid.valid && valid_checkblock.valid);
      this.info.type = valid.error || valid_checkblock.error;
      return !this.info.error;
    },
    set_filter(filter) {
      this.$store.dispatch("set_filters", filter);
    },
    clear_filters() {
      if (!this.disabled_clear_btn) {
        this.$store.dispatch("clear_filters").then(() => {
          this.$store.dispatch("load_applications");
        });
      }
    },
    add_application() {
      if (this.validate()) {
        this.info.loader = true;
        this.info.type = "progress";
        let currentApplication = { ...this.$store.getters.NEW_APPLICATION };
        currentApplication.recording_mode = currentApplication.recording_mode
          ? "button"
          : "scheduled";
        currentApplication.employee_id =
          currentApplication.employee_id.toString();
        delete currentApplication.city_id;
        delete currentApplication.pos_address;
        this.$store
          .dispatch("add_application", currentApplication)
          .then(() => {
            this.info.type = "success";
            this.$store.dispatch("update_new_application", {
              employee_id: "",
              password: "",
              pos_id: "",
              city_id: "",
              pos_address: "",
              recording_mode: false,
              recording_start_time: "",
              recording_end_time: "",
              motion_detection: false,
              email: "",
            });
          })
          .catch((error) => {
            this.info.error = true;
            this.info.type = "failed";
            this.info.text = error.response?.data?.errors.find(
              (x) => x
            )?.message;
          })
          .finally(() => {
            this.info.loader = false;
            setTimeout(() => {
              this.info.type = "";
              this.info.text = "";
              this.info.error = false;
            }, 3000);
          });
      }
    },
  },
};
</script>

<style scoped>
.applications-no-content__text {
  color: gray;
}
.applications-appearance-filter {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  margin-top: 10px;
}
</style>
